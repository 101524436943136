// Table of Content
// ===============================================
// # Variables
// # Elements */

// # Variables
// =============================================== */
// No local variables available

// # Elements
// =============================================== */
.wpcf7 {
	.wpcf7-display-none,
	.screen-reader-response {
		display: none;
	}

	.ajax-loader {
		display: inline-block;
		@include margin(rem((( $input-height - 16rem ) / 2)) 0 0 rem(10px));
	}

	.wpcf7-not-valid-tip {
		@extend label.error;
	}

	.wpcf7-not-valid-tip:before {
		@extend label.error:before;
	}

	input.wpcf7-not-valid {
		@extend input.error;
	}

	select.wpcf7-not-valid {
		@extend select.error;
	}

	textarea.wpcf7-not-valid {
		@extend textarea.error;
	}

	.wpcf7-response-output {
		color: #fff;
		border-radius: 5px;
		background: $color-info;
		@include margin($gutter-base 0);
		@include padding(rem(10px));

		&.wpcf7-validation-errors {
			background: $color-error;
		}

		&.wpcf7-mail-sent-ok {
			background: $color-success;
		}
	}
}
