// Table of Content
// ===============================================
// # Variables
// # Mixins */

// # Variables
// =============================================== */
$table-caption-padding-vertical: rem(10px) !default;
$table-caption-padding-horizontal: rem(10px) !default;
$table-caption-color: #000 !default;
$table-caption-border-size: rem(1px) !default;
$table-caption-border-style: solid !default;
$table-caption-border-color: #ccc !default;
$table-caption-bg: #fff !default;

$table-cell-padding-vertical: rem(10px) !default;
$table-cell-padding-horizontal: rem(10px) !default;

$table-cell-bg: #fff !default;
$table-cell-bg-head: #efefef !default;

$table-cell-border-size: rem(1px) !default;
$table-cell-border-style: solid !default;
$table-cell-border-color: #ccc !default;

// # Mixins
// =============================================== */

@mixin base-tables {
	table {
		width: 100%;
		@include margin(0 0 $gutter-bottom);

		caption {
			color: $table-caption-color;
			border-bottom: $table-caption-border-size $table-caption-border-style $table-caption-border-color;
			background: $table-caption-bg;
			@include padding($table-caption-padding-vertical $table-caption-padding-horizontal);
			@include font-size(($font-size-base * 1.5));
		}

		th,
		td {
			border-bottom: $table-cell-border-size $table-cell-border-style $table-cell-border-color;
			background: $table-cell-bg;
			@include padding($table-cell-padding-vertical $table-cell-padding-horizontal);
		}

		th {
			text-align: left;
			font-weight: bold;
		}

		thead th {
			background: $table-cell-bg-head;
		}

		tfoot {
			font-style: italic;
		}
	}
}
