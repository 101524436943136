// Table of Content
// ===============================================
// # Variables
// # Elements */

// # Variables
// =============================================== */
// No local variables available

// # Elements
// =============================================== */

.transport {
	@include position(relative);

	div {
		@include position(static);

	}

	aside {
		@include padding(rem(98px) null rem(70px));
		@include media($breakpoint-desktop-large) {
			@include padding(rem(30px) null);
		}
		@include media($breakpoint-desktop) {
			@include padding(0);
		}
		@include media($breakpoint-tablet) {
			@include padding(rem(30px) null);
		}

		p {
			line-height: 1.35;
			@include font-size(18px);
			@include margin(null null rem(25px));
			@include media($breakpoint-desktop) {
				@include margin(null null rem(10px));
			}
			&:last-of-type {
				@include margin(null null rem(45px));
				@include media($breakpoint-desktop) {
					@include margin(null null rem(20px));
				}

			}

		}

		h2 {
			text-transform: uppercase;
			font-weight: $heading-font-weight;
			@include font-size(36px);
			@include position(relative);
			@include margin(null null rem(43px));
			@include padding(rem(10px) null null rem(100px));
			@include media($breakpoint-desktop) {
				@include margin(null null rem(20px));
			}

			img {
				max-width: rem(75px);
				@include position(absolute, 0 null null 0);
			}

		}

		h5 {
			font-weight: 900;
			color: $color-gray;
			text-transform: uppercase;
			@include font-size(20px);
			@include margin(null null rem(20px));
			@include media($breakpoint-desktop) {
				@include margin(null null rem(15px));
			}

		}

	}

	.slick-slider {
		@include media(max-width 1580px) {
			@include position(relative);
		}

		.slick-list {
			@include position(relative);

			.slick-track {
				@include position(relative);
			}

		}

		.slick-dots {
			z-index: 9;
			text-align: left;
			max-width: $width-base;
			@include padding(null rem(15px));
			@include margin(null null null rem(-$width-base/2));
			@include position(absolute, null null rem(55px) 50%);
			@include media(max-width 1580px) {
				max-width: 100%;
				text-align: center;
				@include margin(0);
				@include position(absolute, null null rem(30px) 0);
			}

		}

	}

}