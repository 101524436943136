//
// Slick
//
// Table of Content
// ===============================================
// # Variables
// # Imports
// # Overrides */
// # Variables
// =============================================== */
// # Imports
// =============================================== */
@import '../../../../bower_components/slick-carousel/slick/slick.scss';

// # Overrides
// =============================================== */

.slick-slider {

	.slick-slide {

		img {
			width: 100%;
		}

	}

	.slick-arrow {
		z-index: 1;
		border: 0 none;
		width: rem(80px);
		height: rem(80px);
		overflow: hidden;
		text-indent: -999px;
		background-color: $color-gray;
		@include border-top-radius(0);
		@include border-bottom-radius(0);
		@include padding(rem(5px) rem(20px));
		@include margin(rem(-40px) null null);
		@include position(absolute, 50% null null null);
		@include media($breakpoint-phone){
			width: rem(50px);
			height: rem(60px);
		}
		&:hover {
			background-color: $color-success;
		}

		&.slick-prev {
			left: 0;
			@include border-right-radius(40px);
			@include background-image(arrow-prev, svg, center, no-repeat, rem(27px));
		}

		&.slick-next {
			right: 0;
			@include border-left-radius(40px);
			@include background-image(arrow-next, svg, center, no-repeat, rem(27px));
		}

	}

	.slick-dots {
		height: 2px;
		width: 100%;
		text-align: center;
		@include margin(0);

		li {
			height: 2px;
			display: inline-block;
			@include padding(null rem(5px));

			button {
				width: 40px;
				height: 2px;
				border: 0 none;
				overflow: hidden;
				text-indent: -999px;
				background: $color-gray;
				@include padding(0);
			}


			&.slick-active {

				button {
					background: $color-success;
				}

			}

		}

	}



}
