// Table of Content
// ===============================================
// # Variables
// # Elements */

// # Variables
// =============================================== */
// No local variables available

// # Elements
// =============================================== */

.banner {
	min-height: 250px;
	color: $color-white;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center top;
	@include padding(rem(82px) null rem(20px));
	@include media($breakpoint-phone) {
		min-height: 200px;
		@include margin(null null rem(35px));
	}

	h1 {
		font-weight: 900;
		text-transform: uppercase;
		@include margin(0 null rem(5px));
		@include font-size($font-size-h1);
	}

	.breadcrumbs {
		color: $color-white;
		@include font-size(22px);

		a {
			color: $color-gray;
			&:hover {
				color: $color-success;
			}
		}

		i {
			color: $color-gray;
			@include margin(null rem(5px));
		}

	}

}