/*
	jQuery.mmenu effects extension CSS
*/

@import "../../core/css/_inc/variables";


//	Zoom menu
.mm-menu.mm-effect-menu-zoom
{
	-webkit-transition: -webkit-transform $mm_transitionDuration $mm_transitionFunction;
	-moz-transition: -moz-transform $mm_transitionDuration $mm_transitionFunction;
	-ms-transition: -ms-transform $mm_transitionDuration $mm_transitionFunction;
	-o-transition: -o-transform $mm_transitionDuration $mm_transitionFunction;
	transition: transform $mm_transitionDuration $mm_transitionFunction;
}
html
{
	//	Left
	&.mm-opened .mm-menu.mm-effect-menu-zoom
	{
		@include mm_webkit_prefix( 'transform', scale( $mm_zoomScaleDown, $mm_zoomScaleDown ) translate3d( -$mm_subpanelOffset, 0, 0 ) );
		@include mm_webkit_prefix( 'transform-origin', left center );
	}
	&.mm-opening .mm-menu.mm-effect-menu-zoom
	{
		@include mm_webkit_prefix( 'transform', scale( 1, 1 ) translate3d( 0%, 0, 0 ) );
	}

	//	Right
	&.mm-right
	{
		&.mm-opened .mm-menu.mm-effect-menu-zoom
		{
			@include mm_webkit_prefix( 'transform', scale( $mm_zoomScaleDown, $mm_zoomScaleDown) translate3d( $mm_subpanelOffset, 0, 0 ) );
			@include mm_webkit_prefix( 'transform-origin', right center );
		}
		&.mm-opening .mm-menu.mm-effect-menu-zoom
		{
			@include mm_webkit_prefix( 'transform', scale( 1, 1 ) translate3d( 0%, 0, 0 ) );
		}
	}
}


//	Slide menu
.mm-menu.mm-effect-menu-slide
{
	-webkit-transition: -webkit-transform $mm_transitionDuration $mm_transitionFunction;
	-moz-transition: -moz-transform $mm_transitionDuration $mm_transitionFunction;
	-ms-transition: -ms-transform $mm_transitionDuration $mm_transitionFunction;
	-o-transition: -o-transform $mm_transitionDuration $mm_transitionFunction;
	transition: transform $mm_transitionDuration $mm_transitionFunction;
}
html
{
	//	Left
	&.mm-opened .mm-menu.mm-effect-menu-slide
	{
		@include mm_webkit_prefix( 'transform', translate3d( -$mm_subpanelOffset, 0, 0 ) );
	}
	&.mm-opening .mm-menu.mm-effect-menu-slide
	{
		@include mm_webkit_prefix( 'transform', translate3d( 0%, 0, 0 ) );
	}

	//	Right
	&.mm-right
	{
		&.mm-opened .mm-menu.mm-effect-menu-slide
		{
			@include mm_webkit_prefix( 'transform', translate3d( $mm_subpanelOffset, 0, 0 ) );
		}
		&.mm-opening .mm-menu.mm-effect-menu-slide
		{
			@include mm_webkit_prefix( 'transform', translate3d( 0%, 0, 0 ) );
		}
	}
}


//	Fade menu
.mm-menu.mm-effect-menu-fade
{
	opacity: 0;
	@include mm_webkit_prefix( 'transition', opacity $mm_transitionDuration $mm_transitionFunction );
}
html.mm-opening .mm-menu.mm-effect-menu-fade
{
	opacity: 1;
}


//	Zoom panels
.mm-menu.mm-effect-panels-zoom .mm-panel
{
	@include mm_webkit_prefix( 'transform', scale( $mm_zoomScaleUp, $mm_zoomScaleUp ) translate3d( 100%, 0, 0 ) );
	@include mm_webkit_prefix( 'transform-origin', left center );
	
	&.mm-opened
	{
		@include mm_webkit_prefix( 'transform', scale( 1, 1 ) translate3d( 0%, 0, 0 ) );
		
		&.mm-subopened
		{
			@include mm_webkit_prefix( 'transform', scale( $mm_zoomScaleDown, $mm_zoomScaleDown ) translate3d( -$mm_subpanelOffset, 0, 0 ) );
		}
	}
}


//	Slide panels
.mm-menu
{
	&.mm-effect-panels-slide-0 .mm-panel.mm-subopened
	{
		@include mm_webkit_prefix( 'transform', translate3d( 0, 0, 0 ) );
	}

	&.mm-effect-panels-slide-100 .mm-panel.mm-subopened
	{
		@include mm_webkit_prefix( 'transform', translate3d( -100%, 0, 0 ) );
	}
}


//	Listitem basis delay
@mixin mm_effect_listitem_delay( $i )
{
	&:nth-child( #{$i} )
	{
		@include mm_webkit_prefix( 'transition-delay', ( $i * 50ms ) );
	}
}
.mm-menu[class*="mm-effect-listitems-"] .mm-listview > li
{
	@include mm_webkit_prefix( 'transition', none $mm_transitionDuration $mm_transitionFunction );

	@include mm_effect_listitem_delay( 1 );
	@include mm_effect_listitem_delay( 2 );
	@include mm_effect_listitem_delay( 3 );
	@include mm_effect_listitem_delay( 4 );
	@include mm_effect_listitem_delay( 5 );
	@include mm_effect_listitem_delay( 6 );
	@include mm_effect_listitem_delay( 7 );
	@include mm_effect_listitem_delay( 8 );
	@include mm_effect_listitem_delay( 9 );
	@include mm_effect_listitem_delay( 10 );
	@include mm_effect_listitem_delay( 11 );
	@include mm_effect_listitem_delay( 12 );
	@include mm_effect_listitem_delay( 13 );
	@include mm_effect_listitem_delay( 14 );
	@include mm_effect_listitem_delay( 15 );
}


//	Slide list items
.mm-menu.mm-effect-listitems-slide .mm-listview > li
{
	@include mm_webkit_prefix( 'transform', translate3d( 50%, 0, 0 ) );
	opacity: 0;

	-webkit-transition-property: -webkit-transform, opacity;
	-moz-transition-property: -moz-transform, opacity;
	-ms-transition-property: -ms-transform, opacity;
	-o-transition-property: -o-transform, opacity;
	transition-property: transform, opacity;
}
html.mm-opening .mm-menu.mm-effect-listitems-slide .mm-panel.mm-opened .mm-listview > li
{
	@include mm_webkit_prefix( 'transform', translate3d( 0, 0, 0 ) );
	opacity: 1;
}


//	Fade list items
.mm-menu.mm-effect-listitems-fade .mm-listview > li
{
	opacity: 0;
	@include mm_webkit_prefix( 'transition-property', opacity );
}
html.mm-opening .mm-menu.mm-effect-listitems-fade .mm-panel.mm-opened .mm-listview > li
{
	opacity: 1;
}


//	Drop list items
.mm-menu.mm-effect-listitems-drop .mm-listview > li
{
	opacity: 0;
	top: -25%;
	@include mm_webkit_prefix( 'transition-property', ( opacity, top ) );
}
html.mm-opening .mm-menu.mm-effect-listitems-drop .mm-panel.mm-opened .mm-listview > li
{
	opacity: 1;
	top: 0;
}