/*
	jQuery.mmenu dragOpen addon CSS
*/

@import "../../core/css/_inc/variables";

html.mm-opened.mm-dragging
{
	.mm-menu,
	.mm-slideout
	{
		@include mm_webkit_prefix( "transition-duration", 0s );
	}
}