// Table of Content
// ===============================================
// # Variables
// # Elements */

// # Variables
// =============================================== */
// No local variables available

// # Elements
// =============================================== */

* {
	@include margin(0);
	@include padding(0);
}

html,
body {
	font-size: $em-base;
	background-color: $body-bg;
	@include media($breakpoint-tablet) {
		font-size: ceil($em-base * 0.95);
	}
	@include media($breakpoint-phone) {
		font-size: ceil($em-base * 0.85);
	}
}

img {
	height: auto;
	display: block;
	border: 0 none;
	max-width: 100%;
}

a {
	outline: none;
	text-decoration: $link-decoration-base;
	color: $link-color-base;

	&:focus {
		outline: none!important;
	}

	&:link,
	&:visited,
	&:active {
		text-decoration: $link-decoration-base;
		color: $link-color-base;
	}

	&:hover {
		text-decoration: $link-hover-decoration-base;
		color: $link-hover-color-base;
	}
}

.clear {
	@include clearfix;
}


.container {
	width: 100%;
	max-width: $width-base;
	@include clearfix;
}
