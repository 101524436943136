// Table of Content
// ===============================================
// # Variables
// # Elements */

// # Variables
// =============================================== */
// No local variables available

// # Elements
// =============================================== */

.sidebar {
	@include padding(rem(15px) rem(30px) null null);
	@include media($breakpoint-desktop) {
		@include padding(null 0 null null);
	}

	section {
		@include margin(null null rem(30px));
		&:nth-child(2) {
			color: $color-white;
			background: $color-success;
			@include padding(rem(30px) rem(20px) rem(10px));

			h4 {
				font-weight: 700;
				color: $color-white;
				text-transform: uppercase;
				border-bottom: 2px solid rgba($color-white, 0.2);
				@include font-size(24px);
				@include margin(0);
				@include padding(null null rem(28px));
			}

			ul {
				@include margin(0);

				li {
					border-bottom: 1px solid rgba($color-white, 0.2);
					&:last-child {
						border-bottom: 0 none;
					}

					a {
						color: $color-white;
						@include position(relative);
						&.menu-image-hovered {
							&:hover {
								.menu-image-hover-wrapper {
									img {
										opacity: 0;
										&:last-child {
											opacity: 1;
										}
									}
								}
							}
							&:before {
								display: none;
							}

							.menu-image-hover-wrapper {
								display: block;
								@include transform(translateY(-50%));
								@include position(absolute, 50% null null 0);

								img {
									&:last-child {
										opacity: 0;
									}
								}

							}

						}
						&:before {
							color: $color-white;
						}
						&:hover {
							font-weight: 700;
							color: $color-white;
						}

						.menu-image-hover-wrapper {

							img {
								opacity: 0;
								&:last-child {
									opacity: 1 !important;
								}
							}

						}

					}

					&.current-menu-item {

						a {
							font-weight: 700;
							color: $color-white;
						}

					}

				}

			}

		}

		h4 {
			font-weight: 700;
			color: $color-success;
			text-transform: uppercase;
			border-bottom: 2px solid $color-gray;
			@include font-size(24px);
			@include padding(null rem(10px) rem(27px));
			@include margin(0);
		}

		ul {
			@include margin(0);

			li {
				border-bottom: 1px solid $color-gray;
				@include padding(rem(25px) rem(10px));
				&:last-child {
					border-bottom: 0 none;
				}

				a {
					display: block;
					@include clearfix;
					@include position(relative);
					@include padding(null null null rem(33px));
					&.menu-image-hovered {
						@include padding(null null null rem(75px));
						&:hover {
							.menu-image-hover-wrapper {
								img {
									opacity: 1;
									&:last-child {
										opacity: 0;
									}
								}
							}
						}
						&:before {
							display: none;
						}

						.menu-image-hover-wrapper {
							display: block;
							@include transform(translateY(-50%));
							@include position(absolute, 50% null null 0);

							img {
								&:last-child {
									opacity: 0;
								}
							}

						}

					}
					&:before {
						content: "\f105";
						font-weight: 400;
						color: $text-color-base;
						font-family: FontAwesome;
						@include position(absolute, 0 null null 0);
					}
					&:hover {
						font-weight: 700;
						color: $color-success;
					}

				}

				&.current-menu-item {

					> a {
						font-weight: 700;
						color: $color-success;
					}

				}

			}

		}

	}

}