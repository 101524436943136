// Table of Content
// ===============================================
// # Variables
// # Elements */

// # Variables
// =============================================== */
// No local variables available

// # Elements
// =============================================== */

.distribution {
	background-size: contain;
	background-repeat: no-repeat;
	background-position: left top;
	background-color: $color-gray-light;
	@include position(relative);
	@include padding(null null rem(200px));
	@include media($breakpoint-tablet) {
		background-image: none !important;
		@include padding(null null rem(50px));
	}

	aside {
		@include padding(rem(278px) null rem(70px));
		@include media($breakpoint-desktop) {
			@include padding(rem(150px) null rem(70px));
		}
		@include media($breakpoint-tablet) {
			@include padding(rem(20px) null);
		}

		p {
			line-height: 1.35;
			@include font-size(18px);
			@include margin(null null rem(25px));
			@include media($breakpoint-desktop) {
				@include margin(null null rem(15px));
			}
			&:last-of-type {
				@include margin(null null rem(45px));
				@include media($breakpoint-desktop) {
					@include margin(null null rem(20px));
				}

			}

		}

		h2 {
			text-transform: uppercase;
			font-weight: $heading-font-weight;
			@include font-size(36px);
			@include position(relative);
			@include margin(null null rem(43px));
			@include padding(rem(10px) null null rem(100px));
			@include media($breakpoint-desktop) {
				@include margin(null null rem(20px));
			}

			img {
				max-width: rem(75px);
				@include position(absolute, 0 null null 0);
			}

		}

		h5 {
			font-weight: 900;
			color: $color-gray;
			text-transform: uppercase;
			@include font-size(20px);
			@include margin(null null rem(20px));
			@include media($breakpoint-desktop) {
				@include margin(null null rem(15px));
			}

		}

	}

}