/*
	jQuery.mmenu autoHeight addon CSS
*/

@import "../../core/css/_inc/variables";


.mm-menu
{
	&.mm-autoheight
	{
		max-height: percentage( $mm_menuHeight );

		&.mm-fullscreen
		{
			max-height: 100%;
		}
	}

	&.mm-measureheight > .mm-panels > .mm-panel
	{
		bottom: auto !important;
		height: auto !important;
	}
}