// Table of Content
// ===============================================
// # Variables
// # Elements */

// # Variables
// =============================================== */
// No local variables available

// # Elements
// =============================================== */

:focus {
	outline: none;
}

select::-ms-expand {
	display: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	@include margin(0 null null);
}

img {
	display: block;
}

ul,
ol,
li {
	list-style: none;
}

figure {
	@include margin(0);
}

fieldset {
	border: 0;
	@include margin(0);
	@include padding(0);
}
