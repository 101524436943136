/*
	jQuery.mmenu pageshadow extension CSS
*/

@import "../../core/css/_inc/variables";


.mm-menu.mm-pageshadow
{
	&:after
	{
		content: "";
		display: block;
		width: 20px;
		height: 120%;
		position: absolute;
		left: 100%;
		top: -10%;
		z-index: 99;
	}
	
	&.mm-right:after
	{
		left: auto;
		right: 100%;
	}

	&.mm-next:after,
	&.mm-front:after
	{
		content: none;
		display: none;
	}
}

@include mm_colors_pageshadow();