// Table of Content
// ===============================================
// # Variables
// # Elements */

// # Variables
// =============================================== */
// No local variables available

// # Elements
// =============================================== */

.breadcrumbs {
	color: $text-color-base;
	@include font-size(18px);

	a {
		&:hover {
			color: $color-success;
		}

	}

	i {
		@include margin(null rem(5px));
	}

}