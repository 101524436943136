// Table of Content
// ===============================================
// # Variables
// # Elements */

// # Variables
// =============================================== */
$label-margin-bottom: rem(10px) !default;
$label-font-size: $font-size-base !default;
$label-font-weight: 400 !default;
$label-text-color: $text-color-base !default;
$label-text-color-error: #fff !default;
$label-background-error: #d9534f !default;

$input-padding-vertical: rem(10px) !default;
$input-padding-horizontal: rem(15px) !default;

$input-width: 100% !default;
$input-width-textarea: 100% !default;

$input-height: rem(50px) !default;
$input-height-textarea: rem(200px) !default;

$input-font-family: $font-family-base !default;
$input-font-size: 18px !default;

$input-color: #999 !default;
$input-color-placeholder: #999 !default;
$input-color-disabled: #999 !default;

$input-border-size: rem(2px) !default;
$input-border-style: solid !default;
$input-border-color: $color-gray !default;
$input-border-color-focus: $color-success !default;
$input-border-color-error: $color-error !default;
$input-radius: rem(0) !default;

$input-bg: #fff !default;
$input-bg-disabled: #efefef !default;

$input-submit-color: #333 !default;
$input-submit-color-hover: #333 !default;

$input-submit-bg: #efefef !default;
$input-submit-bg-hover: #efefef !default;

$fieldset-padding-vertical: rem(20px) !default;
$fieldset-padding-horizontal: rem(20px) !default;

$fieldset-border-size: rem(1px) !default;
$fieldset-border-style: solid !default;
$fieldset-border-color: #ccc !default;
$fieldset-border-radius: rem(5px);

$legend-color: #666 !default;
$legend-border-size: rem(1px) !default;
$legend-border-style: solid !default;
$legend-border-color: #ccc !default;
$legend-border-radius: rem(5px) !default;
$legend-background: #fff !default;

// # Elements
// =============================================== */
fieldset {
	border: $fieldset-border-size $fieldset-border-style $fieldset-border-color;
	border-radius: $fieldset-border-radius;
	@include margin(null null $gutter-bottom);
	@include padding($fieldset-padding-vertical $fieldset-padding-horizontal);
	@include no-gutter-last-child;

	> p {
		@include margin(null null $gutter-bottom);
		@include clearfix;
	}
}

legend {
	color: $legend-color;
	border: $legend-border-size $legend-border-style $legend-border-color;
	border-radius: $legend-border-radius;
	background: $legend-background;
	@include padding(rem(5px) rem(10px));
}

label {
	&:not(.radio):not(.checkbox):not(.error) {
		display: block;
		font-weight: $label-font-weight;
		color: $label-text-color;
		@include margin(null null $label-margin-bottom);
		@include font-size($label-font-size);
	}

	&.radio {
		display: inline-block;
		height: $input-height;
		line-height: $input-height;
		@include margin(0 rem(10px) 0 rem(1px));
	}

	&.checkbox {
		display: inline-block;
		height: $input-height;
		line-height: $input-height;
		@include margin(0 rem(10px) 0);
	}

	&.error {
		display: inline-block;
		color: $label-text-color-error;
		border-radius: rem(3px);
		background: $label-background-error;
		@include margin(rem(10px) null null);
		@include padding(rem(5px) rem(10px));
		@include position(relative);

		&:before {
			display: block;
			content: '';
			width: 0;
			height: 0;
			border-left: rem(5px) solid transparent;
			border-right: rem(5px) solid transparent;
			border-bottom: rem(5px) solid $label-background-error;
			@include position(absolute, rem(-5px) null null rem(10px));
		}
	}
}

input,
select,
textarea {
	@extend .form-control;
	height: $input-height;
	display: block;
	font-family: $input-font-family;
	color: $input-color;
	border: $input-border-size $input-border-style $input-border-color;
	box-shadow: none;
	-webkit-box-shadow: none;
	@include border-top-radius($input-radius);
	@include border-bottom-radius($input-radius);
	@include padding($input-padding-vertical $input-padding-horizontal);
	@include font-size($input-font-size);
	@include placeholder ($input-color-placeholder);
	@include margin(null null rem(10px));

	&:focus:not(:read-only):not([type='submit']):not([type='reset']):not([type='button']) {
		border-color: $input-border-color-focus;
	}

	&.error {
		border-color: $input-border-color-error;
	}

	&:disabled {
		color: $input-color-disabled;
		background-color: $input-bg-disabled;
	}

	&[type='radio'],
	&[type='checkbox'] {
		display: inline-block;
		height: auto;
		@include margin(null rem(10px) null null);
		@include padding(0);
	}

	&[type='radio'],
	&[type='checkbox'],
	&[type='submit'],
	&[type='reset'],
	&[type='button'] {
		display: inline-block;
		width: auto;
	}
}

button {
	display: inline-block;
	width: auto;
}

button,
input[type='submit'],
input[type='reset'],
input[type='button'] {
	color: $input-submit-color;
	background-color: $input-submit-bg;

	&:hover {
		color: $input-submit-color-hover;
		background-color: $input-submit-bg-hover;
	}
}

select {
	color: $input-color-placeholder;
	@include appearance(none);
	@include background-image(select, svg, right, no-repeat, rem(35px));
}

textarea {
	resize: none;
	width: $input-width-textarea;
	height: $input-height-textarea;
}

span[role="alert"]{
	display: none!important;
}