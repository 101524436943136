//
// Maps
//
// Table of Content
// ===============================================
// # Variables
// # Elements */

// # Variables
// =============================================== */
$maps-z-index: 10 !default;
$maps-height: rem(450px) !default;

$maps-infobox-padding: 30px !default;
$maps-infobox-width: 250px !default;
$maps-infobox-border-radius: 5px !default;
$maps-infobox-background: #fff !default;

// # Elements
// =============================================== */
.maps {
	width: 100%;
	height: $maps-height;
	z-index: $maps-z-index;
	@include position(relative);
	@include margin(null null rem(50px));
	@include media($breakpoint-desktop) {
		height: rem(400px);
	}

	.maps__infobox {
		width: $maps-infobox-width;
		border-radius: $maps-infobox-border-radius;
		background: $maps-infobox-background;
		@include padding($maps-infobox-padding);
		@include position(relative);

		&:before {
			@include arrow(10px, down, $maps-infobox-background);
			@include position(absolute, null null -10px 20px);
		}
	}
}
