// Table of Content
// ===============================================
// # Variables
// # Elements */

// # Variables
// =============================================== */
// No local variables available

// # Elements
// =============================================== */

.slider {
	color: $color-white;
	@include position(relative);

	h1 {
		font-weight: 900;
		line-height: 1.05;
		text-transform: uppercase;
		@include font-size($font-size-h2);
		@include margin(null null rem(38px));
	}

	p {
		font-weight: 200;
		line-height: 1.3;
		@include margin(null null rem(40px));
	}

	.btn {

		i {
			@include margin(null null null rem(60px));
		}

	}

	.slick-list {
		width: 100%;
	}

	.slick-slide {
		width: 100%;
		overflow: hidden;

		> div {
			width: 100%;
			height: 72vh;
			display: table;
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center top;

			aside {
				display: table-cell;
				vertical-align: middle;
				@include padding(rem(50px) null rem(65px));

				> div {
					@include position(relative);
					&:after {
						content: "";
						width: 2000px;
						display: block;
						border-top: 3px solid $color-white;
						border-bottom: 2px solid $color-white;
						@include padding(rem(1px) null);
						@include margin(rem(-15px) null null);
						@include position(absolute, 50% 102.5% null null);
					}

				}

			}

		}

	}

}
