// Table of Content
// ===============================================
// # Variables
// # Elements */

// # Variables
// =============================================== */
$button-padding-vertical: rem(10px) !default;
$button-padding-horizontal: rem(25px) !default;

$button-font-family: $font-family-base !default;
$button-font-size: 18px !default;
$button-font-weight: 600 !default;

$button-color: $text-color-base !default;
$button-color-hover: #fff !default;
$button-color-disabled: #999 !default;

$button-border-size: rem(3px) !default;
$button-border-style: solid !default;
$button-border-color: $color-success !default;
$button-border-color-hover: $color-success !default;
$button-border-color-disabled: #666 !default;
$button-border-radius: rem(25px) !default;

$button-bg: transparent !default;
$button-bg-hover: $color-success !default;
$button-bg-disabled: #efefef !default;

// # Elements
// =============================================== */
.btn,
.button,
.button:link,
.button:visited {
	line-height: normal;
	display: inline-block;
	cursor: pointer;
	text-align: center;
	vertical-align: middle;
	text-transform: uppercase;
	font-family: $button-font-family;
	font-weight: $button-font-weight;
	color: $button-color;
	touch-action: manipulation;
	white-space: nowrap;
	border: $button-border-size $button-border-style $button-border-color;
	border-radius: $button-border-radius;
	background: $button-bg;
	@include margin(null null 0);
	@include padding($button-padding-vertical $button-padding-horizontal);
	@include user-select(none);
	@include font-size($button-font-size);
	@include transition(0.3s);

	i {
		@include margin(null null null rem(50px));
	}

	&.button--success {
		border-color: #4cae4c;
		background: #5cb85c;

		&:hover,
		&[type='submit']:hover,
		&[type='reset']:hover,
		&[type='button']:hover,
		&:focus,
		&.focus {
			border-color: #4cae4c;
			background-color: #4cae4c;
		}
	}

	&:hover,
	&[type='submit']:hover,
	&[type='reset']:hover,
	&[type='button']:hover,
	&:focus,
	&.focus {
		color: $button-color-hover;
		text-decoration: none;
		border-color: $button-border-color-hover;
		background-color: $button-bg-hover;
	}

	&:active,
	&.active {
		background-image: none;
	}

	&:disabled,
	&.disabled {
		color: $button-color-disabled;
		background-color: $button-bg-disabled;
	}

	&.btn-primary {
		border: 0 none;
		color: $color-white;
		background: rgba($color-white, 0.15);
		@include font-size(18px);
		@include padding(rem(14px) rem(28px));
		&:hover {
			background: rgba($color-white, 0.25);
		}

	}

	&.btn-success {
		border: 0 none;
		font-weight: 600;
		color: $color-white;
		background: $color-success;
		@include font-size(18px);
		@include padding(rem(14px) rem(28px));
		&:hover {
			background: darken($color-success, 5%);
		}

	}

}
