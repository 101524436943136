// Table of Content
// ===============================================
// # Variables
// # Elements */

// # Variables
// =============================================== */
// No local variables available

// # Elements
// =============================================== */

.tools {
	background: $color-gray-light;
	@include padding(rem(26px) null);
	@include margin(null null rem(60px));

	.ssba {
		text-align: right;

		> div {
			display: inline-block;
			@include font-size(18px);

			a {
				width: 24px;
				height: 24px;
				line-height: 24px;
				text-align: center;
				color: $color-white;
				display: inline-block;
				background: $color-gray;
				@include margin(null null null rem(4px));
				&:hover {
					background: $color-success;
				}
				&:first-of-type {
					@include margin(null null null rem(10px));
				}

				img {
					display: none !important;
				}

				&:after {
					content: "";
					font-size: 14px;
					font-family: FontAwesome;
				}

				&.ssba_facebook_share {
					&:after {
						content: "\f09a";
					}
				}
				&.ssba_twitter_share {
					&:after {
						content: "\f099";
					}

				}
				&.ssba_google_share {
					&:after {
						content: "\f0d5";
					}

				}

			}

		}

	}

}