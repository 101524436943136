// Table of Content
// ===============================================
// # Variables
// # Mixins */

// # Variables
// =============================================== */
// No local variables available

// # Mixins
// =============================================== */

@mixin base-blockquotes {
	blockquote {
		font-style: italic;
		border: rem(1px) solid #ddd;
		border-radius: rem(5px);
		@include margin(0 0 $gutter-bottom);
		@include padding(rem(20px));
		@include no-gutter-last-child;
	}
}
