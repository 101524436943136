// Table of Content
// ===============================================
// # Variables
// # Mixins */

// # Variables
// =============================================== */
// No local variables available

// # Mixins
// =============================================== */

@mixin base-alignment {
	.align-none,
	.alignnone {
		display: inline;
	}

	.align-center,
	.aligncenter {
		text-align: center;
		@include margin(0 auto);
	}

	.align-left,
	.alignleft {
		float: left;
		@include margin(0 $gutter-base $gutter-bottom 0);
	}

	.align-right,
	.alignright {
		float: right;
		@include margin(0 0 $gutter-bottom $gutter-base);
	}

	.wp-caption,
	figure {
		@include margin(0 0 $gutter-bottom);

		.wp-caption-text,
		figcaption {
			font-style: italic;
			@include margin(rem(5px));
		}
	}
}
