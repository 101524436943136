// Table of Content
// ===============================================
// # Variables
// # Elements */

// # Variables
// =============================================== */
// No local variables available

// # Elements
// =============================================== */

.work {
	@include padding(rem(75px) null rem(50px));

	h4 {
		font-weight: 900;
		overflow: hidden;
		text-align: center;
		text-transform: uppercase;
		@include font-size($font-size-h4);
		@include margin(null null rem(40px));

		span {
			display: inline-block;
			@include position(relative);
			@include padding(null rem(20px));
			&:before,
			&:after {
				content: "";
				width: 2000px;
				display: block;
				border-top: 1px solid $color-gray;
				border-bottom: 1px solid $color-gray;
				@include padding(1px null);
				@include transform(translateY(-50%));
				@include position(absolute, 50% null null 100%);
			}
			&:after {
				@include position(absolute, 50% 100% null auto);
			}

		}

	}

	.slick-slider {

		.slick-slide {
			@include padding(null rem(15px));
			img {
				opacity: 0.5;
				width: auto;
				@include margin(null auto);
			}

		}

	}

}