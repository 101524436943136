/*
	jQuery.mmenu toggles addon CSS
*/

@import "../../core/css/_inc/variables";


input.mm-toggle,
input.mm-check
{
	position: absolute;
	left: -10000px;
}

label.mm-toggle,
label.mm-check
{
	margin: 0;
	position: absolute;
	top: 50%;
	z-index: 2;
	
	&:before
	{
		content: '';
		display: block;
	}
}

// styling
label.mm-toggle
{
	border-radius: $mm_toggleHeight;
	width: $mm_toggleWidth;
	height: $mm_toggleHeight;
	margin-top: -( $mm_toggleHeight / 2 );

	&:before
	{
		border-radius: $mm_toggleHeight;
		width: $mm_toggleHeight - 2;
		height: $mm_toggleHeight - 2;
		margin: 1px;
	}
}
input.mm-toggle:checked ~ label.mm-toggle:before
{
	float: right;
}

label.mm-check
{
	width: $mm_checkWidth;
	height: $mm_checkHeight;
	margin-top: -( $mm_checkHeight / 2 );

	&:before
	{
		border-left: 3px solid;
		border-bottom: 3px solid;
		width: 40%;
		height: 20%;
		margin: 25% 0 0 20%;
		opacity: 0.1;

		@include mm-webkit-prefix( 'transform', rotate( -45deg ) );
	}
}
input.mm-check:checked ~ label.mm-check:before
{
	opacity: 1;
}


li.mm-vertical label
{
	&.mm-toggle,
	&.mm-check
	{
		bottom: auto;
		margin-top: 0;
	}
	&.mm-toggle
	{
		top: ( $mm_btnSize - $mm_toggleHeight ) / 2;
	}
	&.mm-check
	{
		top: ( $mm_btnSize - $mm_checkHeight ) / 2;
	}
}


// positioning
label
{
	&.mm-toggle,
	&.mm-check
	{
		right: $mm_padding * 2;
	}
	
	&.mm-toggle
	{
		+ a,
		+ span
		{
			padding-right: $mm_toggleWidth + ( $mm_padding * 3 );
		}
	}
	
	&.mm-check
	{
		+ a,
		+ span
		{
			padding-right: $mm_checkWidth + ( $mm_padding * 3 );
		}
	}
}

// positioning with subopen
a.mm-next + label
{
	&.mm-toggle,
	&.mm-check
	{
		right: $mm_subopenWidth + $mm_padding;
		
		+ a,
		+ span
		{
			margin-right: $mm_subopenWidth;
		}
	}
	&.mm-toggle
	{
		+ a,
		+ span
		{
			padding-right: $mm_toggleWidth + ( $mm_padding * 2 );
		}
	}
	&.mm-check
	{
		+ a,
		+ span
		{
			padding-right: $mm_checkWidth + ( $mm_padding * 2 );
		}
	}
}

// positioning with counter
em.mm-counter + a.mm-next + label
{
	&.mm-toggle,
	&.mm-check
	{
		right: $mm_counterWidth + $mm_subopenWidth + $mm_padding;

		+ a,
		+ span
		{
			margin-right: $mm_counterWidth + $mm_subopenWidth;
		}
	}
}

@include mm_colors_toggles;
@include mm_colors_checks;