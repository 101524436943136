// Table of Content
// ===============================================
// # Variables
// # Elements */

// # Variables
// =============================================== */
// No local variables available

// # Elements
// =============================================== */

.quote {
	height: 0;
	z-index: 9;
	@include position(relative);
	@include media($breakpoint-tablet) {
		height: auto;
		@include padding(null 15px);
	}

	aside {
		line-height: 1.3;
		max-width: 580px;
		font-weight: 300;
		text-align: center;
		font-style: italic;
		color: $color-white;
		background: $color-success;
		@include transform(translateY(-50%));
		@include padding(rem(40px) rem(90px));
		@include position(absolute, 0 0 null null);
		@include media($breakpoint-desktop) {
			@include padding(rem(20px) rem(40px));
		}
		@include media($breakpoint-tablet) {
			max-width: 100%;
			@include position(static);
			@include transform(translateY(0));
		}

		p {
			@include margin(0);
			@include font-size(40px);
			@include position(relative);
			@include padding(rem(30px) null);
			@include media($breakpoint-desktop) {
				@include font-size(35px);
			}
			&:before {
				content: "“";
				line-height: 0;
				font-weight: 900;
				@include font-size(48px);
				@include position(absolute, 15px null null 0);
			}
			&:after {
				content: "”";
				line-height: 0;
				font-weight: 900;
				@include font-size(48px);
				@include position(absolute, null 0 0 null);
			}

		}

	}

}