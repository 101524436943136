// Table of Content
// ===============================================
// # General
// # Grid
// # Typography */

// # General
// =============================================== */
// Proper REM calculation
$em-base: 16px; // Do not change this unless you know what you are doing

// Image Path
$image-path: '../../img' !default;

// Retina suffix
$retina: false !default;
$retina-suffix: '@2x' !default;

// Body
$body-bg: #f2f2f2 !default;

// Page
$page-bg: #fff !default;

// Width
$width-base: 1430px !default; // Mainly used for centering

// Gutters
$gutter-base: 30px !default;
$gutter-top: 0 !default;
$gutter-right: $gutter-base !default;
$gutter-bottom: $gutter-base !default;
$gutter-left: $gutter-base !default;
$gutter-side: $gutter-base !default; // Used when base width is reached
$gutter-side-tablet: ($gutter-base / 2) !default; // Used when base width is reached
$gutter-side-phone: ($gutter-base / 2) !default; // Used when base width is reached

// Colors
$color-error: #d9534f !default;
$color-success: #007d48 !default;
$color-info: #5bc0de !default;
$color-gray: #cccccc !default;
$color-gray-light: #f9f9f9 !default;
$color-dark: #1d1d1b !default;
$color-black: #000 !default;
$color-white: #fff !default;

// Breakpoints
$screen-xs: 480px !default;
$screen-sm: 768px !default;
$screen-md: 992px !default;
$screen-lg: 1200px !default;
$screen-xlg: 1300px !default;
$breakpoint-small: max-width $screen-xs - 1 !default;
$breakpoint-phone: max-width $screen-sm - 1 !default;
$breakpoint-tablet: max-width $screen-md - 1 !default;
$breakpoint-desktop: max-width $screen-lg - 1 !default;
$breakpoint-desktop-large: max-width $screen-xlg - 1 !default;

// # Typography
// =============================================== */
$text-color-base: #000 !default;

$link-color-base:  #000 !default;
$link-decoration-base: none !default;
$link-hover-color-base: $color-success !default;
$link-hover-decoration-base: none !default;

$font-family-base: 'Source Sans Pro', Arial, sans-serif !default;
$font-size-base: 20px !default;
$font-size-h1: 60px !default;
$font-size-h2: 48px !default;
$font-size-h3: 30px !default;
$font-size-h4: 24px !default;
$font-size-h5: 22px !default;
$font-size-h6: 10px !default;

$line-height-base: 1.5 !default; // Always use integer no px or rem values

$heading-font-family: inherit !default;
$heading-font-weight: 900 !default;
$heading-line-height: 1.1 !default;
$heading-color: inherit !default;
