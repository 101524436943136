// Table of Content
// ===============================================
// # Variables
// # Elements */

// # Variables
// =============================================== */
// No local variables available

// # Elements
// =============================================== */

.scroll {
	@include padding(null null rem(105px));
	@include media($breakpoint-phone) {
		display: none;
		@include padding(null null rem(50px));
	}

	ul {

		li {

			a {
				z-index: 1;
				height: 100%;
				display: block;
				@include position(absolute, 0 15px null 15px);
				&:hover,
				&:focus {
					background-color: transparent;
				}

			}

			> div {
				background-repeat: no-repeat;
				border-bottom: 3px solid darken($color-gray, 15%);
				@include position(relative);
				@include padding(rem(42px) null rem(22px) rem(70px));
				@include media($breakpoint-tablet) {
					@include padding(rem(30px) null rem(10px) rem(50px));
				}
				@include media($breakpoint-phone) {
					@include margin(null null rem(20px));
				}

				img {
					@include position(absolute, rem(47px) null null 0);
					@include media($breakpoint-tablet) {
						max-width: 35px;
						@include position(absolute, rem(33px) null null 0);
					}

				}

				h2 {
					font-weight: 900;
					text-transform: uppercase;
					@include font-size(36px);
					@include media($breakpoint-tablet) {
						@include font-size(25px);
					}

				}

			}

			&:hover,
			&.current {

				> div {
					min-height: 202px;
					color: $color-white;
					border-bottom: 0 none;
					background: $color-success;
					background-repeat: no-repeat;
					background-position: right bottom;
					@include padding(rem(25px) rem(30px));
					@include margin(rem(-80px) null null);
					@include background-image(scroll-img1, png, 120% rem(70px), no-repeat);
					@include media($breakpoint-tablet) {
						min-height: 152px;
					}
					@include media($breakpoint-phone) {
						@include margin(0 null null);
					}

					img {
						display: none;
					}

				}

			}

		}

	}

}