// Table of Content
// ===============================================
// # Variables
// # Elements */

// # Variables
// =============================================== */
// No local variables available

// # Elements
// =============================================== */

.footer {

	.footer__info {
		z-index: 1;
		max-width: 98%;
		overflow: hidden;
		@include margin(null auto);
		@include position(relative);
		@include border-right-radius(5px);
		@include border-left-radius(5px);
		@include padding(rem(30px) null);
		@include background-image(footer-info-bg, jpg, center, no-repeat, cover);
		&:before {
			z-index: -1;
			content: "";
			width: 100%;
			height: 100%;
			display: block;
			background: rgba($color-success, 0.95);
			@include position(absolute, 0 null null 0);
		}

		ul {
			@include margin(null null 0);

			li {
				min-height: 75px;
				text-align: center;
				color: $color-white;
				border-left: 1px solid rgba($color-white, 0.3);
				@include padding(rem(10px) null);
				@include media($breakpoint-phone) {
					border: 0 none;
				}
				&:first-child {
					border: 0 none;
					text-align: left;
					@include media($breakpoint-desktop) {
						text-align: center;
					}

				}
				&:last-child {
					text-align: right;
					@include media($breakpoint-desktop) {
						text-align: center;
					}

				}

				.footer__info__logo {
					display: inline-block;
					@include margin(rem(15px) null null);
				}

				div {
					text-align: left;
					display: inline-block;
				}

				h4 {
					font-weight: 700;
					color: $color-white;
					@include margin(0);
					@include font-size($font-size-h4);
					@include media($breakpoint-tablet) {
						text-align: center;
					}

					i {
						@include margin(null rem(5px) null null);
					}

				}

				a {
					color: $color-white;
				}

				p {
					@include margin(0);
					@include font-size(18px);
					@include media($breakpoint-tablet) {
						text-align: center;
					}
				}

				span {
					text-align: left;
					display: inline-block;
					font-family: 'rabioheadregular', Arial, sans-serif;
					@include font-size(30px);
					@include padding(null rem(10px) null null);

				}

				ol {
					display: inline-block;

					li {
						min-height: 0;
						border: 0 none;
						display: inline-block;
						@include padding(0 rem(2px));

						a {
							display: block;
							width: rem(40px);
							height: rem(40px);
							text-align: center;
							color: $color-success;
							background: $color-white;
							@include font-size(22px);
							@include border-top-radius(50%);
							@include border-bottom-radius(50%);

							i {
								line-height: rem(40px);
							}

						}

					}

				}

			}

		}

	}

	.footer__middle {
		@include padding(rem(70px) null);
		@include background-image(footer-middle-bg, png, center, no-repeat, 100%);
		@include media($breakpoint-phone) {
			@include padding(rem(50px) null rem(30px));
		}

		section {
			@extend .col-lg-3, .col-sm-6;
			@include media($breakpoint-desktop) {
				min-height: 255px;
				@include margin(null null rem(30px));
			}
			@include media($breakpoint-tablet) {
				min-height: 200px;
			}
			@include media($breakpoint-phone) {
				min-height: 0;
				text-align: center;
				@include margin(null null rem(40px));
			}

			h4,
			h5 {
				font-weight: 700;
				color: $color-success;
				@include font-size(22px);
				@include margin(null null rem(42px));
				@include media($breakpoint-desktop) {
					@include margin(null null rem(20px));
				}
			}
			h5 {
				color: $text-color-base;
				@include margin(null null rem(5px));
				@include font-size(22px);

			}

			p {
				@include margin(null null rem(30px));
			}

			a {
				font-weight: 700;
				color: $color-success;
				@include font-size(22px);
				@include media($breakpoint-desktop-large) {
					@include font-size(20px);
				}

				i {
					color: $text-color-base;
					@include margin(null rem(10px) null null);
				}

			}

			ul {

				li {

					a {
						font-weight: 400;
						color: $text-color-base;
						@include position(relative);
						@include padding(null null null rem(20px));
						&:before {
							content: "\f105";
							font-family: FontAwesome;
							@include position(absolute, rem(-1px) null null 0);
						}

						&:hover {
							color: $color-success;
						}

						img {
							display: none;
						}

					}

				}

			}

		}

	}

	.footer__copyright {
		background: $body-bg;
		@include font-size(18px);
		@include padding(rem(25px) null);
		@include media($breakpoint-desktop) {
			text-align: center;
			@include padding(rem(15px) null);
		}

		p {
			@include margin(0);
		}

		a {
			color: $text-color-base;
			&:hover {
				color: $color-success;
			}
		}

		ul {
			text-align: right;
			@include margin(0);
			@include media($breakpoint-desktop) {
				text-align: center;
			}

			li {
				display: inline-block;
				@include position(relative);
				@include padding(null rem(10px));
				&:before {
					width: 1px;
					content: "";
					height: rem(18px);
					display: block;
					background: $color-gray;
					@include position(absolute, rem(5px) null null rem(-2px));
				}
				&:first-child {

					&:before {
						display: none;
					}

				}

			}

		}

	}

	.footer__up {
		z-index: 9;
		opacity: 0;
		font-weight: 700;
		width: rem(80px);
		height: rem(80px);
		line-height: 0.70;
		text-align: center;
		visibility: hidden;
		color: $color-white;
		background: rgba($color-black, 0.35);
		@include font-size(18px);
		@include transition(0.3s);
		@include padding(rem(20px) null);
		@include border-top-radius(5px);
		@include border-bottom-radius(5px);
		@include position(fixed, null rem(20px) rem(20px) null);

		i {
			display: block;
		}

		&:hover {
			background: rgba($color-black, 0.70);
		}

		&.visible {
			opacity: 1;
			visibility: visible;
		}

	}

}
