// Table of Content
// ===============================================
// # Variables
// # Mixins */

// # Variables
// =============================================== */
// No local variables available

// # Mixins
// =============================================== */

@mixin base-lists {
	ul,
	ol {
		@include margin(0 $gutter-base $gutter-bottom);
	}

	li ul,
	li ol {
		@include margin(0 $gutter-base);
	}

	ul,
	ul li {
		list-style-type: disc;
	}

	ol,
	ol li {
		list-style-type: decimal;
	}

	dl {
		@include margin(0 0 $gutter-bottom);

		dt {
			font-weight: bold;
		}

		dd {
			@include margin(null null null $gutter-base);
		}
	}
}
