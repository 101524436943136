// Table of Content
// ===============================================
// # Variables
// # Elements */

// # Variables
// =============================================== */
// No local variables available

// # Elements
// =============================================== */

.header {
	z-index: 100;
	height: 120px;
	background: $color-white;
	@include position(absolute, 0 0 null 0);
	@include media($breakpoint-desktop) {
		height: auto;
		@include position(relative, 0 0 null 0);
	}

	&.header--sticky {
		height: 80px;
		@include position(fixed, 0 0 null 0);
		@include media($breakpoint-desktop) {
			@include position(relative, null null null null);
		}

		.header__bottom {
			@include position(absolute, 0 null auto null);
		}

		.header__top {

			.header__top__logo {
				@include padding(0 null null null);

				a {
					background: none;
					@include padding(0);
					&:after {
						display: none;
					}
				}

			}

		}

		.header__menu {
			@include padding(rem(50px) null);
		}

	}

	.header__top {
		@include padding(rem(30px) null null);
		@include media($breakpoint-desktop) {
			@include padding(rem(10px) null);
		}
		@include media($breakpoint-phone) {
			@include padding(null null 0);
		}

		.header__top__logo {
			float: left;
			@include padding(rem(6px) null null);
			@include position(relative);
			@include media($breakpoint-desktop-large) {
				max-width: 475px;
			}
			@include media($breakpoint-tablet) {
				max-width: 300px;
			}
			@include media($breakpoint-phone) {
				max-width: 245px;
			}

			a {
				display: inline-block;
				@include position(relative);
				@include background-image('thumb-icon', 'png', right bottom, no-repeat, auto 100%);
				@include padding(rem(11px) rem(145px) rem(36px) null);
				@include media($breakpoint-tablet) {
					background: none;
					@include padding(rem(22px) null);
					@include padding(null 0 null null);
				}
				@include media($breakpoint-phone) {
					@include padding(rem(18px) null);
				}
				&:after {
					content: "";
					display: block;
					border-top: rem(2px) solid $color-dark;
					border-bottom: rem(1px) solid $color-dark;
					@include padding(1px null null);
					@include position(absolute, rem(44px) rem(147px) null -1000px);
					@include media($breakpoint-desktop-large) {
						@include position(absolute, rem(40px) rem(135px) null null);
					}
					@include media($breakpoint-tablet) {
						@include position(absolute, rem(50px) 0 null null);
					}
					@include media($breakpoint-phone) {
						@include position(absolute, rem(48px) 0 null null);
					}

				}

			}

		}

		.header_top__opener {
			width: 27px;
			float: right;
			display: block;
			@include position(relative);
			@include padding(7px null);
			@include margin(rem(22px) null null);
			&:before {
				content: "";
				height: 3px;
				width: 100%;
				display: block;
				background: $color-success;
				@include transition(0.3s);
				@include border-radius(2px);
				@include position(absolute, 0 null null 0);
			}

			&:after {
				content: "";
				height: 3px;
				width: 100%;
				display: block;
				background: $color-success;
				@include transition(0.3s);
				@include border-radius(2px);
				@include position(absolute, null null 0 0);
			}

			span {
				width: 100%;
				height: 3px;
				display: block;
				background: $color-success;
				@include transition(0.3s);
				@include border-radius(2px);
			}

			&.header_top__opener--active {

				&:before {
					top: 7px;
					transform: rotate(45deg);
				}
				&:after {
					bottom: 7px;
					transform: rotate(-45deg);
				}

				span {
					opacity: 0;
				}

			}

		}

		aside {
			float: right;
			@include media($breakpoint-desktop) {
				@include padding(rem(23px) null null null);
			}
			@include media($breakpoint-phone) {
				float: none;
				clear: both;
				@include padding(0 null null null);
			}

			strong {
				font-weight: 400;
				color: rgba($text-color-base, 0.65);
				@include font-size(18px);
				@include padding(null rem(20px) null null);
				@include media($breakpoint-desktop) {
					display: none;
				}
			}

			ul {
				display: inline-block;
				vertical-align: top;

				li {
					display: inline-block;
					@include position(relative);
					@include padding(null rem(18px));
					@include media($breakpoint-desktop) {
						@include padding(null rem(10px));
					}
					&:before {
						width: 1px;
						content: "";
						height: 18px;
						display: block;
						background: rgba($color-black, 0.1);
						@include position(absolute, rem(8px) null null 0);
					}
					&:first-child {
						@include padding(null null null 0);

						&:before {
							display: none;
						}

					}

					a {
						line-height: normal;
						color: rgba($text-color-base, 0.65);
						@include font-size(18px);
						&:hover {
							color: $color-success;
						}

					}

					&.current-menu-item {

						a {
							color: $color-success;
						}

					}

				}

				&.social {
					@include media($breakpoint-desktop) {
						display: none;
					}

					li {

						a {
							color: $color-success;
						}

					}

				}

				&.menu {
					@include media($breakpoint-phone) {
						display: none;
					}

				}

			}

			.header__top__lang {
				float: right;
				text-transform: uppercase;
				@include font-size(15px);
				@include padding(rem(2px) rem(15px) null rem(10px));
				@include media($breakpoint-phone) {
					float: none;
					clear: both;
					background: $color-success;
					@include margin(null rem(-25px));
					@include padding(rem(7px) rem(25px));
				}

				> div {
					@include padding(0);
				}

				ul {
					@include media($breakpoint-phone) {
						@include margin(0);
					}

					li {
						vertical-align: middle;
						display: inline-block;
						@include padding(0 rem(7px));
						@include position(relative);
						&:before {
							display: none;
						}

						a {
							width: auto;
							height: rem(26px);
							text-align: center;
							color: $color-success;
							display: inline-block;
							line-height: rem(24px);
							@include padding(0);
							@include font-size(15px);
							@include media($breakpoint-phone) {
								color: $color-white;
							}

						}

						&.wpml-ls-current-language {
							@include padding(0 rem(10px));

							a {
								display: block;
								min-width: 50px;
								font-weight: 700;
								text-align: center;
								color: $text-color-base;
								background: lighten($color-gray, 15%);
								@include font-size(15px);
								@include border-top-radius(12px);
								@include border-bottom-radius(12px);
							}

						}

					}

				}

			}

		}

	}

	.header__bottom {
		z-index: 2;
		@include position(absolute, null 0 rem(-40px) 0);
		@include media($breakpoint-desktop) {
			@include position(relative, null null auto null);
		}

		aside {
			z-index: 1;
			width: 100%;
			float: right;
			max-width: 700px;
			color: $color-white;
			background: $color-success;
			@include position(relative);
			@include padding(rem(13px) null);
			@include media($breakpoint-desktop) {
				float: none;
				max-width: 100%;
				@include margin(0);
			}
			&:before {
				z-index: -1;
				content: "";
				height: 100%;
				width: 5000px;
				display: block;
				background: $color-success;
				@include position(absolute, 0 null null 100%);
				@include media($breakpoint-desktop) {
					@include position(absolute, 0 -100px null -100px);
				}

			}

			strong {
				vertical-align: middle;
				display: inline-block;
				text-transform: uppercase;
				@include font-size(18px);
				@include padding(rem(12px) rem(14px) rem(12px) rem(20px));
				@include media($breakpoint-desktop) {
					@include padding(null null null 0);
				}

				a {
					color: $color-white;

					i {
						@include margin(null rem(10px) null null);
					}

				}

				cite {
					display: none;
					font-style: normal;
				}

				&.active {

					cite {
						display: inline-block;
					}

					span {
						display: none;
					}

					a {

						i {

							&:before {
								content: "\f00d";
							}

						}

					}

				}

			}

			p {
				display: inline-block;
				vertical-align: middle;
				@include margin(0);
				@include position(relative);
				@include padding(rem(12px) null rem(12px) rem(20px));
				&:before {
					content: "";
					display: block;
					width: rem(1px);
					height: rem(18px);
					background: $color-white;
					@include position(absolute, rem(20px) null null 0);
				}

				a {
					color: $color-white;
				}

			}

			.btn {
				float: right;

				i {
					@include font-size(20px);
					@include margin(null null null rem(60px));
				}

			}

		}

	}

	.header__menu {
		z-index: 1;
		width: 100%;
		display: none;
		background: rgba(0, 0, 0, 0.98);
		@include position(absolute, 100% null null 0);
		@include padding(rem(193px) null rem(100px));
		@include media($breakpoint-desktop) {
			@include padding(rem(100px) null rem(50px));
		}

		h4 {
			font-weight: 900;
			color: $color-success;
			text-transform: uppercase;
			@include padding(null rem(10px) rem(12px));
			@include media($breakpoint-desktop) {
				@include font-size(18px);
			}

		}

		ul {
			@include margin(null null rem(50px));

			li {
				border-top: 1px solid rgba($color-white, 0.15);
				@include padding(rem(24px) null rem(25px) rem(10px));
				@include media($breakpoint-desktop) {
					@include padding(rem(15px) null);
				}
				&:first-child {
					border-top-width: 2px;
				}

				a {
					display: block;
					color: $color-white;
					@include position(relative);
					@include padding(null null null rem(38px));
					&:before {
						content: "\f105";
						color: $color-success;
						font-family: FontAwesome;
						@include position(absolute, 0 null null 0);
					}

					&:hover {
						color: $color-success;
					}

					&.menu-image-hovered {
						@include padding(null null null 0);
						@include margin(null null null rem(-10px));

						img {
							width: rem(50px);
							opacity: 1!important;
							@include margin(null rem(15px) null null);

							&.hovered-image {
								display: none;
							}

						}

						&:before {
							display: none;
						}

					}

				}

				&.current-menu-item {

					a {
						color: $color-success;
					}

				}

			}

		}

	}

	.header__mobile {
		display: none;
	}

}
