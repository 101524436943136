// Table of Content
// ===============================================
// # Variables
// # Elements */

// # Variables
// =============================================== */
// No local variables available

// # Elements
// =============================================== */

.article {

	h2 {
		font-weight: 900;
		color: $color-success;
		text-transform: uppercase;
		@include font-size($font-size-h2);
		@include margin(null null rem(30px));
	}

	.article__content {
		@include padding(null null rem(30px));

		h3 {
			text-transform: uppercase;
			@include margin(null null rem(35px));
		}

		p {
			@include margin(null null rem(40px));
		}

		iframe {
			width: 100% !important;
			height: 450px !important;
			@include margin(null null rem(0));
			@include media($breakpoint-desktop) {
				height: 400px !important;
			}
			@include media($breakpoint-phone) {
				height: 300px !important;
			}
			@include media($breakpoint-small) {
				height: 200px !important;
			}

			.ytp-title {
				display: none !important;
			}

		}

		ul {
			@include margin(null null rem(30px));

			li {
				color: $color-success;
				border-bottom: 1px solid rgba($color-gray, 0.5);
				@include position(relative);
				@include padding(rem(4px) null rem(4px) rem(30px));
				&:before {
					content: "\f105";
					font-family: FontAwesome;
					@include position(absolute, rem(4px) null null rem(5px));
				}
				&:first-child {
					border-top: 1px solid rgba($color-gray, 0.5);
				}

			}

		}

		.slick-slider {
			@include margin(null null rem(60px));

			.slick-arrow {
				background-color: rgba($color-success, 0.75);
				@include margin(0);
				@include transition(0.3s);
				&:hover {
					background-color: $color-success;
				}

				&.slick-prev {
					left: 0;
				}

				&.slick-next {
					right: 0;
				}

			}

		}

	}

	.article__contact {

		section {
			@include padding(rem(20px) null rem(40px));

			h4 {
				font-weight: 700;
				color: $color-success;
				@include font-size(20px);
				@include margin(null null rem(35px));
			}

			address {
				@include position(relative);
				@include padding(null null null rem(40px));

				span {
					display: block;
					@include margin(null null rem(30px));
				}

				p {
					@include margin(0);
				}

				i {
					color: $color-success;
					@include position(absolute, rem(5px) null null 0);
				}

			}

			ul {

				li {
					color: $color-success;
					border-bottom: 1px solid rgba($color-gray, 0.5);
					@include padding(rem(5px) null);
					&:first-child {
						border-top: 1px solid rgba($color-gray, 0.5);
					}

					span {
						color: $text-color-base;
					}

					i {
						@include margin(null rem(15px) null null);
					}

				}

			}

		}

		button[type="submit"] {
			@include margin(rem(20px) null null);

			i {
				font-size: 110%;
				@include margin(null null null rem(20px));
			}

		}

		form {
			@include padding(rem(20px) null rem(40px));
		}

	}

}