// Table of Content
// ===============================================
// # Variables
// # Elements */

// # Variables
// =============================================== */
// No local variables available

// # Elements
// =============================================== */

.wrapper {
	width: 100%;
	overflow: hidden;
	color: $text-color-base;
	background-color: $page-bg!important;
	font-family: $font-family-base;
	line-height: $line-height-base;
	@include clearfix;
	@include padding(rem(120px) null null null);
	@include font-size($font-size-base);
	@include media($breakpoint-desktop) {
		@include padding(0 null null null);
	}

	&.wrapper--page {

	}
}
